.time-picker {
  max-width: 80%;
  margin-left: 1rem;
}

@media screen and (max-width: 575px) {
  .time-picker {
    max-width: 50%;
  }
}

.form-heading {
  margin: 1rem 0 1rem 5px;
}
