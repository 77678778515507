/* @import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss"; */
/*Root*/
:root {
  --reeler: #1ee9be;
  --reeler-light: #77f2d8;
  --reeler-super-light: #d1faf2;
  --reeler-mid-green: #77f2d8;
  --reeler-dark-green: #11aa89;
  --reeler-darkest-green: #0c755e;
  --reeler-light-grey: #f8f8f8;
  --reeler-grey: #f2f2f5;
  --reeler-grey-middle: #e2e2e9;
  --reeler-grey-dark: #d9d9d9;
  --reeler-grey-darker: #b3b3b3;
  --reeler-container: #f2f2f5;
  --reeler-container-yellow: #f3f2ef;
  --reeler-link: #707070;
  --reeler-input: #ced4da;
  --reeler-icon: #707070;
  --reeler-hover: #3c404314;
  --reeler-title: #4c4c4c;
  --reeler-badge: #4c4c4c;
  --reeler-black: #3c3c3c;
  --reeler-danger: #eb5f5f;
  --reeler-warning: #ffc107;
  --reeler-warning-light: #ffecb5;
  --reeler-white: #ffffff;
  --reeler-edit: #007bff;
  --reeler-edit-hover: #0069d9;
  --color-attention-subtle: #fff8c5;
  --color-attention-muted: rgba(212, 167, 44, 0.4);
  --reeler-danger-light: #fdbfbf;
  --reeler-danger-hover: #c01717;
  --reeler-shadow: rgba(112, 112, 112, 0.13) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  --reeler-shadow-inset: rgba(112, 112, 112, 1) 3.2px inset;
  --reeler-o-25: rgba(32, 233, 189, 0.25);
  --reeler-light-o-25: rgba(119, 242, 215, 0.25);
  --bootstrap-border: 1px solid #dee2e6;
}
#root {
  background: var(--reeler-white);
}

body {
  color: var(--reeler-title);
}

/*Auth*/
.auth_background {
  background: var(--reeler-container);
}

/* Links and buttons*/

.navlink-font {
  color: var(--reeler-link);
}
.navlink-font:hover {
  text-decoration: none;
  color: var(--reeler);
}
.link {
  color: var(--reeler-link);
}

.link:hover {
  text-decoration: underline;
  color: var(--reeler);
}

.nav-link {
  color: var(--reeler-link);
}
.nav-link.active {
  text-decoration: underline;
}

.nav-link:hover {
  text-decoration: underline;
  color: var(--reeler);
}

.active-main-nav {
  color: var(--reeler-link);
  border-bottom: 2px var(--reeler) solid;
}

.settings-nav-link {
  color: var(--reeler-link);
  padding: 0.5rem 1rem;
}
.active-settings-nav {
  color: var(--reeler-link);
  text-decoration: underline 1px solid;
}

.account-profile-link {
  padding: 0.5rem;
  clear: both;
  font-weight: 400;
  color: var(--reeler-black);
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--reeler-light-grey);
    width: inherit;
    text-decoration: none;
    color: var(--reeler-black);
  }
}

.btn-link {
  font-weight: 400 !important;
  color: var(--reeler-icon) !important;
  text-decoration: none !important;
}
.btn-link:hover {
  font-weight: 400 !important;
  color: var(--reeler-grey-darker) !important;
  text-decoration: underline !important;
}

.btn-facebook {
  background-color: #1877f2;
  width: 100%;
  border: 0 !important;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0 24px;
  color: #fff;
  background-color: #1877f2;
}
.btn-main {
  color: var(--reeler-black) !important;
  background-color: var(--reeler-light) !important;
  border: var(--reeler) !important;
}
.btn-main-light {
  background-color: var(--reeler-super-light) !important;
  border-color: var(--reeler-icon) !important;
  color: var(--reeler-icon) !important;
}

.secondary-btn {
  background-color: transparent !important;
  color: var(--reeler-title) !important;
  border: 1px solid var(--reeler-grey-darker) !important;
}

.btn-filter {
  background-color: #fff !important;
  border-color: var(--reeler-grey-dark) !important;
  color: var(--reeler-icon) !important;
}

.btn-delete {
  color: var(--reeler-black) !important;
  background-color: var(--reeler-danger-light) !important;
  border: var(--reeler-danger-light) !important;
}
.btn-delete:hover {
  color: #fff !important;
  background-color: var(--reeler-danger-hover) !important;
  border: var(--reeler-danger) !important;
}
.btn-edit {
  color: #fff !important;
  background-color: var(--reeler-edit) !important;
  border: var(--reeler-edit) !important;
}
.btn-edit:hover {
  color: #fff !important;
  background-color: var(--reeler-edit-hover) !important;
  border: var(--reeler-edit) !important;
}
.btn-secondary {
  color: var(--reeler-title) !important;
  background-color: #fff !important;
  border: 1px solid var(--reeler-grey-dark) !important;
}
.btn-secondary:hover {
  color: var(--reeler-title) !important;
  background-color: var(--reeler-grey-dark) !important;
  border: 1px solid var(--reeler-title) !important;
}
.btn-secondary:disabled {
  color: var(--reeler-grey-dark) !important;
  background-color: transparent !important;
  border: 1px solid var(--reeler-grey-dark) !important;
}
.btn-main:hover {
  background-color: var(--reeler) !important;
  border-color: var(--reeler) !important;
}
.btn-main:focus {
  border-color: var(--reeler-light) !important;
  box-shadow: 0 0 0 0.2rem var(--reeler-o-25) !important;
}
.btn-main:disabled {
  color: var(--reeler-black) !important;
  background-color: var(--reeler-light) !important;
  border: var(--reeler-black) !important;
}
.btn-main:hover:disabled {
  color: var(--reeler-black) !important;
  background-color: var(--reeler-light) !important;
  border: var(--reeler-black) !important;
}

.input-group-btn {
  border-radius: 0 0.25em 0.25em 0 !important;
  background-color: var(--reeler-grey) !important;
}
.input-group-btn:disabled {
  color: var(--reeler-grey-light) !important;
  background-color: var(--reeler-grey-light) !important;
}

/* Navbar*/
.nav_background {
  background: #fff;
  border-bottom: var(--reeler-grey-dark) solid 1px;
}

.logo {
  color: var(--reeler) !important;
  font-family: "Changa One", cursive;
}

.logo-link {
  color: var(--reeler-link) !important;
  font-family: "Changa One", cursive;
}

.navbar-light .navbar-brand {
  color: "#fff";
}

#dropdown-basic::before {
  display: none;
}

.whitegrey {
  background-color: #fff;
  border-color: #fff;
  color: var(--reeler-icon);
}

#search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: "#606060";
  opacity: 1; /* Firefox */
}

.secondary_nav_background {
  background: #fff;
}

.settings-sidebar {
  width: 200px;
  height: 100vh;
  float: left;
  align-items: start;
}

.container_background {
  background: var(--reeler-container);
  min-height: 100vh;
}

/* secondary-nav */
.secondary-nav a.nav-link {
  margin: 0 0.1em;
}
.secondary-nav a.nav-link.active {
  background: var(--reeler-hover);
  border-radius: 10px;
  color: var(--reeler-link);
}

.secondary-nav a.nav-link:hover {
  background: var(--reeler-hover);
  border-radius: 10px;
  color: var(--reeler-link);
}

/* Icons */
.icon-color {
  color: var(--reeler-icon);
}

.icon-color-active {
  color: var(--reeler);
}

.icon-color-inactive {
  color: var(--reeler-grey-darker);
}

.icon-star-active {
  color: var(--reeler);
}

.icon-campaign-active {
  color: var(--reeler);
  width: 0.8rem;
}

.icon-campaign-inactive {
  color: var(--reeler-grey-dark);
  width: 0.8rem;
}

.icon-asset-rights-pending {
  color: var(--reeler-orange);
  width: 1rem;
}

.icon-asset-rights-approved {
  color: var(--reeler);
  width: 1rem;
}

.icon-asset-new {
  color: var(--reeler-icon);
  width: 1rem;
}

.icon-asset-rejected {
  color: var(--reeler-danger);
  width: 1rem;
}

.asset-library {
  color: var(--reeler);
  width: 1rem;
}

.campaign-status {
  cursor: pointer;
}

.campaign-status-caret {
  color: var(--reeler-icon);
}

.btn {
  cursor: pointer;
}
.icon-btn {
  cursor: pointer;
}

.icon-btn:active {
  color: var(--reeler);
}

.icon-reeler {
  color: var(--reeler);
}

/* Dropdowns */
.reeler-dropdown {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.75rem !important;
  border: none !important;
  padding: 1rem !important;
  max-width: 500px !important;
  z-index: 99;
  background: var(--reeler-white);
}

/* Headings */
.title,
.sub-title,
.label-title {
  color: var(--reeler-title);
}

.title {
  font-size: 1.3rem;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sub-title {
  padding-top: 20px;
  padding-bottom: 15px;
  text-decoration: underline 1px solid;
}

.label-title {
  font-size: 1em;
  font-weight: 400;
}

/* Campaign */
.campaign-header-field {
  margin-left: 1rem;
  display: flex !important;
  align-items: center;
}

.asset {
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #dee2e6 !important;
  background: #f8f9fa !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
}
.asset:hover {
  border: 1px solid var(--reeler) !important;
}

.list-asset {
  border-radius: 3px;
  border: 1px solid #dee2e6 !important;
  background: #f8f9fa !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
}
.list-asset:hover {
  border: 1px solid var(--reeler) !important;
}

/* ASSET Tabs */
.asset-tab-title {
  font-size: 0.75rem;
}

.campaign-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 0.75rem;
  padding: 0.2rem;
  border-radius: 3px;
  border: 2px solid #dee2e6 !important;
  background: var(--reeler-white);
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
}

.campaign-type:hover {
  border: 2px solid var(--reeler) !important;
}

.campaign-type-selected {
  background: var(--reeler-super-light);
  border: 2px solid var(--reeler) !important;
}

/* Card */
.card-title {
  font-size: 1rem;
}

/* Footer */
.footer {
  border-top: #eaeaea solid 1px;
  background: #fff;
}

/* Badge */
.badge-main {
  top: 0px !important;
  color: var(--reeler-black) !important;
  background-color: var(--reeler-light) !important;
}

/* Spinner */
.spinner-color {
  color: var(--reeler);
}

/* ugc landingpage */
.ugc_landingpage_container {
  background-color: #fff;
}

/* Form */
.form-label {
  font-weight: 500;
}
.form-label-larger {
  font-size: larger;
}

.form-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  outline: none;
  background: var(--reeler-light-grey);
  border: 1px solid var(--reeler-grey-dark);
  opacity: 0.8;
  transition: opacity 0.4s;
  border-radius: 15px;
  accent-color: var(--reeler);
}

.form-range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--reeler);
  border: 1px solid var(--reeler);
  border-radius: 50%;
  cursor: pointer;
}

.reeler-input-field {
  border-radius: 20px !important;
}

.reeler-input {
  border-radius: "8px" !important;
  background: "var(--reeler-container)" !important;
}

/* UGC table */
.ugc-table-icon-width {
  width: 20px;
}

/* Modal asset view */
.modal-90w {
  width: 90%;
  max-width: none !important;
}
.modal-90h {
  height: 90%;
  max-height: none !important;
}

/* Asset info */

.asset-info-text {
  font-size: 12px;
}

.asset-title {
  font-size: 14px;
  font-weight: 500;
}

#asset-info {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

#asset-info.modal-dialog {
  max-width: 100%;
  height: 100vh;
}
#asset-info .modal-body {
  height: 100%;
  overflow-y: scroll;
}
#asset-info .modal-content {
  height: 100%;
}

#right-requests.modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

/* Tag */
.tag {
  background: var(--reeler-grey);
  border: none;
  border-radius: 10rem;
  cursor: pointer;
}
.tag:disabled {
  background: var(--reeler-grey);
  cursor: default;
  border: none;
  border-radius: 10rem;
  color: var(--reeler-black);
}

.filter-tag {
  background: var(--reeler-super-light);
  border-radius: 10rem;
  cursor: pointer;
}

.collection-tag {
  background: #fff;
  border-radius: 10rem;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  font-size: 12px;
  color: var(--reeler-title);
}

/* Asset image ratio box */
.image-ratio-box {
  border: 1px solid var(--reeler-icon) !important;

  border-radius: 3px;
  font-size: 12px;
}

.image-ratio-box:hover {
  color: var(--reeler);
  border: 1px solid var(--reeler) !important;
}
.image-ratio-box-selected {
  color: var(--reeler);
  border: 1px solid var(--reeler) !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

/* FORMS */
.form-control {
  color: var(--reeler-title);
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
  color: var(--reeler-title);
}

.form-control:focus {
  color: var(--reeler-title) !important;
  border-color: var(--reeler-light) !important;
  box-shadow: 0 0 0 0.2rem var(--reeler-o-25) !important;
}

.form-label {
  color: var(--reeler-title) !important;
  font-weight: 500 !important;
}

/* Notification bar*/

.notification-link {
  color: var(--reeler-link);
  text-decoration: underline;
}

#special-btn {
  display: inline;
  padding: 0;
  width: 15%;
}

/* Text editor */
.text-editor {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.editor-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
}

.capitalize {
  text-transform: capitalize;
}

#asset-modal-fullscreen {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

#form-fields-tab.nav-tabs.nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: var(--reeler-container);
}
#form-fields-tab.nav-tabs.nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
