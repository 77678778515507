.tooltip.show {
  opacity: 1 !important;
}

.tooltip.bs-tooltip-top .tooltip-inner,
.tooltip.bs-tooltip-bottom .tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  color: #fff !important;
  background-color: var(--reeler-black);
  opacity: 1 !important;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: var(--reeler-black) !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: var(--reeler-black) !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: var(--reeler-black) !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: var(--reeler-black) !important;
  margin-top: -1px;
}
