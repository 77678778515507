.sidebar-title {
  font-size: 1.2em;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub-container {
  border: 1px solid var(--reeler-grey-dark);
  padding: 0.5em !important;
  border-radius: 5px;
}

.bg-reeler-grey {
  background-color: var(--reeler-grey);
}

.sidebar-sub-title {
  color: var(--reeler-title);
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
}

.clickable-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.toggle-button {
  color: var(--reeler-title);
  background-color: white !important;
  border: 1px solid var(--reeler-grey-dark) !important;
  font-size: 0.8em !important;
  margin: 0 1em;
  border-radius: 5px !important;
}

.btn-group > :first-child {
  margin-left: 0 !important;
}

.btn-group > :last-child {
  margin-right: 0 !important;
}

.toggle-button > label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5em;
}
.toggle-button > input {
  display: none;
}

.btn-group > .btn.active {
  background-color: var(--reeler-super-light) !important;
  border-color: var(--reeler-icon) !important;
  box-shadow: var(--reeler-shadow-inset);
  z-index: 0 !important;
}

.block {
  width: 100%;
}

.toggle-button {
  margin: 0;
  padding: 0;
  width: 100%;
}

.pinch {
  letter-spacing: -0.5px;
}

.tag-input {
  border-right: 0 !important;
}
