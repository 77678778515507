/* SIDEbase hack*/
.css-vj11vy.ps-menu-root {
  height: 100%;
}
.css-ewdv3l {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.css-honxw6 {
  border-right: 1px solid currentcolor !important;
  border-bottom: 1px solid currentcolor !important;
}

.css-jn69v9 {
  border-right: 1px solid currentcolor !important;
  border-bottom: 1px solid currentcolor !important;
}

.css-1097eso {
  display: inline-block !important;
  -webkit-transition: -webkit-transform 0.3s !important;
  transition: transform 0.3s !important;
  border-right: 1px solid currentcolor !important;
  border-bottom: 1px solid currentcolor !important;
  width: 5px !important;
  height: 5px !important;
  -webkit-transform: rotate(-45deg) !important;
  -moz-transform: rotate(-45deg) !important;
  -ms-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
}
